@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap");

@import "~bootstrap/scss/bootstrap";

body {
	margin: 0px !important;
	padding: 0px !important;
	background-color: #ffffff;
	font-family: "Inter", "sans-serif";
}

select:disabled {
	opacity: 0.9 !important;
}

.container {
	display: flex;
	flex-direction: column;
	margin: auto;
	max-width: 576px;
	width: 100%;
	min-height: 100vh;
	padding: 0px !important;
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

::-webkit-scrollbar {
	width: 0px;
}

a:hover {
	text-decoration: initial !important;
}

.PrivateSwitchBase-root-1 {
	padding: 0 9px 0 0 !important;
}

.MuiFormControlLabel-root {
	margin: 0px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-start !important;
	align-items: flex-start !important;
}

.MuiTypography-body1 {
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 22px !important;
	color: #020f20 !important;
}

.MuiFormGroup-root {
	gap: 8px !important;
}

.MuiOutlinedInput-root {
	background: #f9fafa !important;
}

.MuiInputLabel-outlined {
	color: #2f3435 !important;
}

.MuiOutlinedInput-input {
	padding: 30px 16px 12px 16px !important;
	font-size: 14px !important;
	line-height: 22px !important;
	font-family: "Inter", "sans-serif" !important;
	font-weight: 600 !important;
	color: #2f3435 !important;
}

.MuiOutlinedInput-notchedOutline {
	border: 1px solid #e5e9ea !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #83888a !important;
	border-radius: 8px !important;
	color: #2f3435 !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	font-family: "Inter", "sans-serif" !important;
	color: #83888a !important;
	font-weight: 400 !important;
	font-size: 10px !important;
	line-height: 16px !important;
	transform: translate(16px, 10px) !important;
}

.MuiOutlinedInput-root legend {
	max-width: 0px !important;
}

.MuiFormLabel-root {
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 18px !important;
	color: #656b72 !important;
	margin-bottom: 8px !important;
}

.MuiFormHelperText-contained {
	margin-left: 0px !important;
}

.MuiCheckbox-root {
	padding: 0px !important;
	padding-right: 8px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: #00465d !important;
}

.MuiInputBase-input input {
	color: #020f20 !important;
	font-size: 14px !important;
	line-height: 22px !important;
	font-weight: 500 !important;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.hh-32 {
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
}

.hh-24 {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
}

.hh-20 {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
}

.tt-20 {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}

.tt-18 {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
}

.tt-16 {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.tt-14 {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
}

.tt-12 {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

.tt-10 {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
}

.color-blue {
	color: #003db5 !important;
}

.color-green {
	color: #45bd75 !important;
}

.color-red {
	color: #e24f4f !important;
}

.color-gray-100 {
	color: #020f20;
}

.color-gray-200 {
	color: #1f2935;
}

.color-gray-300 {
	color: #3c4249;
}

.color-gray-400 {
	color: #656b72;
}

.color-gray-500 {
	color: #7c8085;
}

.color-gray-600 {
	color: #bcc2c3;
}

.color-gray-800 {
	color: #e5e9ea;
}

.color-gray-900 {
	color: #f5f7f9;
}

.color-white {
	color: #ffffff;
}

.color-black {
	color: #000000;
}

.bold {
	font-weight: 700;
}

.semibold {
	font-weight: 600;
}

.medium {
	font-weight: 500;
}

.regular {
	font-weight: 400;
}

.uppercase {
	text-transform: uppercase;
}

.button-primary {
	width: 100%;
	background: #003db5;
	color: #ffffff;
	border-radius: 4px;
	height: 48px;
	min-height: 48px;
	border: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;
}

.button-primary:hover {
	color: #ffffff;
}

.button-primary:disabled {
	background: #e7e9ec;
	color: #7c8085;
}

input[type="file"] {
	display: none;
}

.button-secondary {
	width: 100%;
	background: #ffffff;
	color: #020f20;
	border-radius: 4px;
	height: 48px;
	min-height: 48px;
	border: 1px solid #e7e9ec;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;
}

.button-secondary:disabled {
	background: #e7e9ec;
	color: #7c8085;
}

.button-transparent {
	width: 100%;
	padding-top: 17px;
	padding-bottom: 19px;
	background: transparent;
	color: #bcc2c4;
	border: 1px solid #00465d;
	border-radius: 12px;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.button-no-style {
	width: 100%;
	border: none;
	background: none;
}

.header-return {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 20px 24px 20px;
	background-color: #f5f7f9;
	min-height: 66px;
	position: fixed;
	width: 100%;
	max-width: 576px;
	z-index: 10;
}

.barra-progresso {
	background-color: #3c7eff;
	height: 2px;
	position: fixed;
	max-width: 576px;
	margin-top: 66px;
	z-index: 10;
}

.header-return p {
	margin-bottom: 0px;
}

.overlay {
	position: absolute;
	margin-top: -14px;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(15, 18, 19, 0.4) 0%, rgba(15, 18, 19, 0.9) 76.33%);
}

#chat {
	overflow-y: scroll;
	max-height: 40vh;
}

#chat::-webkit-scrollbar {
	display: none;
}

#chat {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.header-login {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 58px 0px 37px 0px;
}

.header-autoavaliacao {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 70px 0px 125px 0px;
}

.menu-lateral {
	height: 100%;
	width: 200px;
	position: absolute;
	z-index: 10;
	background-color: #ffffff;
	overflow-x: hidden;
}

.menu-overlay {
	height: calc(100vh - 66px);
	bottom: 0;
	width: 576px;
	position: absolute;
	z-index: 5;
	background-color: #020f20;
	opacity: 0.5;
	overflow-x: hidden;
}

.close-button {
	position: absolute;
	top: 0;
	font-size: 36px;
	margin-left: 20px;
	cursor: pointer;
}

.menu-button {
	position: absolute;
	margin-left: 20px;
	top: 80px;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.texto-menu {
	margin: 0px;
	color: #020f20;
}

.texto-menu:hover {
	margin: 0px;
	color: #020f20;
}

.modal-responder-overlay {
	height: calc(100vh);
	top: 0;
	width: 576px;
	position: absolute;
	z-index: 5;
	background-color: #020f20;
	opacity: 0.5;
}
